import Head from '@components/Head';
import Template from '@components/layout/template';
import NotFound from '@components/NotFound';
import RenderBlocks from '@components/RenderBlocks';
import { GetStaticPaths, GetStaticProps } from 'next';
import React, { ReactElement } from 'react';
import { API_URL, nullGetStaticProps } from 'lib/constants';
import { Footer, Page, SocialProfiles } from '@payload/types';
import InternalError from '@components/InternalError';
import Maintenance from '@components/Maintenance';

export type Props = {
  page: Page | null;
  isPreview: boolean;
  status?: number;
  footer: Footer;
  socialProfiles: SocialProfiles;
  maintenanceMode: '0' | '1';
};

export default function RenderPage(props: Props): ReactElement | null {
  const { page, isPreview, status, maintenanceMode } = props;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const host = window.location.host;
      if (host.includes('threat-hunting.co.uk')) {
        window.location.href =
          'https://telesoft-technologies.com/threat-hunting-service';
      }
    }
  }, []);

  if (maintenanceMode === '1' && !isPreview) {
    return <Maintenance />;
  }

  if (status === 500) {
    return <InternalError />;
  }

  if (!page) {
    return <NotFound />;
  }

  if (!isPreview && page.status !== 'published') {
    return <NotFound />;
  }

  return (
    <Template previewMode={isPreview} variant={page.options?.variant}>
      <Head
        title={page.meta?.title || page.title}
        description={page.meta?.description}
        // @ts-ignore
        ogImage={page.meta?.image?.url}
      />
      <RenderBlocks blocks={page.blocks} />
    </Template>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  const res = await fetch(`${API_URL}/pages?limit=500`);
  const json = await res.json();

  return {
    paths: json.docs.map(({ slug }: { slug: string }) => ({
      params: { slug: slug.split('/') },
    })),
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const maintenanceMode = process?.env?.MAINTENANCE_MODE ?? '0';
  const slug = context.params?.slug || 'home';
  const isPreview = !!context.preview;
  const res = await fetch(`${API_URL}/pages?where[slug][equals]=${slug}`);

  if (!res.ok) {
    return {
      ...nullGetStaticProps,
      props: {
        status: 500,
      },
    };
  }

  const json = await res.json();

  if (json.totalDocs === 0) {
    return nullGetStaticProps;
  }

  return {
    props: {
      page: json.docs[0],
      isPreview,
      maintenanceMode,
    },
    revalidate: 10,
  };
};
